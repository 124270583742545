<template>
    <div class="pa-3 fill-height">
        <v-card flat>
            <v-card-title class="pb-0">Open to buy</v-card-title>
            <v-card-text>
                <metabase type="dashboard" width="100%" height="880px" resource="13" :params="{}" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Metabase from '@/components/Metabase.vue';

export default {
    components: {
        Metabase
    }    
}
</script>

<style lang="scss" scoped>

</style>